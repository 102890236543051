import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../components/Footer/Footer';

import { headData } from '../mock/data';
import '../style/main.scss';

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forget me</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="If you would like to make us forget you and all you data" />
      </Helmet>
      <section id="hero" className="jumbotron">
        <Container>
            <h1 className="hero-title text-center">
              You have all the rights on your data!{' '}
            </h1>
            <p>
              If you would like to enquiry us about all the data collected and want to make us forget everything,
            </p>
            <p>
              Just let us know by writing the <a href="mailto:team@theysay.news">email</a>
            </p>
            <br></br>
            <p className="hero-cta justify-content-center">
              <Link className="cta-btn cta-btn--hero" to="/">
                Home page
              </Link>
            </p>
        </Container>
        {/* <Footer /> */}
      </section>
    </>
  );
};
